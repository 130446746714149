import hagen from "hagen";

import { useEffect, useState } from "react";
import useFirebase from "./useFirebase";
import {
	createAnonymousAccount,
	createPlayer,
	signInWithPasswordlessEmail,
} from "../libs/auth";

export default function useLogin() {
	const firebase = useFirebase();

	const [user, setUser] = useState();
	const [authLoading, setAuthLoading] = useState(true);

	// when firebase initializes
	useEffect(() => {
		// wait for firebase to actually initialize
		if (!firebase) return;

		// check for the currently logged in user
		return firebase.auth().onAuthStateChanged((user_) => {
			// set the user with the user object (or null if they're not logged in)
			setUser(user_);

			// set a flag to notify that we're done with user loading
			setAuthLoading(false);
		});
	}, [firebase]);

	// every time the user or loading state changes
	useEffect(() => {
		// wait for the current user to stop loading
		if (authLoading) return;

		if (!user) {
			hagen.log(`AUTH`, `User is not logged in`);
		} else {
			hagen.log(
				`AUTH`,
				`User is logged in: ${
					user.isAnonymous ? `Anonymous` : user.email
				} as ${user.uid}`
			);
		}

		if (!user) {
			// if the user has not been logged in
			// sign in anonymously
			createAnonymousAccount();
		} else if (
			firebase.auth().currentUser.isAnonymous &&
			firebase
				.auth()
				.isSignInWithEmailLink(window.location.href)
		) {
			// if the user has already been logged in anonymously
			// and the user is trying to log in with a passwordless email link

			// sign them in (linking their anonymous account in the process)
			signInWithPasswordlessEmail();
		}
	}, [user, authLoading]);

	useEffect(() => {
		if (!user) return;

		// save the user to firebase
		createPlayer();
	}, [user]);

	return user;
}
