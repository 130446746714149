import { useEffect, useState } from "react";
import getFirebase from "../libs/firebase";

export default function useFirebase() {
	const [instance, setInstance] = useState();

	useEffect(() => {
		setInstance(getFirebase());
	}, []);

	return instance;
}
