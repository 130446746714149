import React, { useEffect } from "react";
import { navigate } from "gatsby";

// ========= UTILS =========
import { getPlayerState } from "../libs/auth";

// ========= HOOKS =========
import useLogin from "../hooks/useLogin";

// ========= COMPONENTS =========
import PageBackground from "../components/Elements/PageBackground";

// ========= MAIN =========
const Index = () => {
	const user = useLogin();

	useEffect(() => {
		if (!user) return;
		(async () => {
			const ps = await getPlayerState();
			if (ps?.pledge) navigate(`/quiz`);
			if (ps?.skippedPledge) navigate(`/quiz`);
			navigate(`/pledge`);
		})();
	}, [user]);

	return <PageBackground />;
};

export default Index;
